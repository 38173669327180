import { SEPARATE_ATTACHMENT_NAME_SINGULAR } from './../attachment-settings-api.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { concatMap } from 'rxjs/operators';

import { NotificationService } from '../../../services/notification.service';
import { AttachmentSettingAPIService, START_ATTACHMENT_NAME_SINGULAR } from '../attachment-settings-api.service';
import { Subscription } from 'rxjs';
import { FileProcessingService } from '../../../services/file-processing.service';


@Component({
  selector: 'js-add-attach-setting',
  templateUrl: './add-attach-setting.component.html',
  styleUrls: ['./add-attach-setting.component.scss']
})
export class AddAttachSettingComponent implements OnInit, OnDestroy {

  @ViewChild('fileUploader')
  fileUploader: DxFileUploaderComponent;

  subscriptions: Subscription[] = [];
  form: UntypedFormGroup;
  mode: string;

  fileChosen = false;
  submitting = false;
  wrongType = false;
  error = false;
  type: any;

  constructor(
    public attachService: AttachmentSettingAPIService,
    private fileService: FileProcessingService,
    public activeModal: NgbActiveModal,
    private notiService: NotificationService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.setupForm();
  }

  ngOnInit() {
  }

  submit() {
    if ((this.type === 'xlsx' || this.type === 'xls' || this.type === 'xlsm')
      && this.mode !== SEPARATE_ATTACHMENT_NAME_SINGULAR) {
      this.notiService.showInfo('Can only use spreadsheets for Separte attachments');
    } else {
      this.submitting = true;
      this.form.disable();

      const formData = new FormData();
      for (const file of this.fileUploader.value) {
        formData.append(file.name, file);
      }

      const setting = {
        description: this.form.get('description').value,
        printOnlyIfFullyCompleted: this.form.get('printOnlyIfFullyCompleted').value,
        printWithSalesQuote: this.form.get('printWithSalesQuote').value,
        printWithContractQuote: this.form.get('printWithContractQuote').value,
        attachAtTheStart: this.mode === START_ATTACHMENT_NAME_SINGULAR ? true : false,
        useMailMerge: this.form.get('useMailMerge').value,
        printSeparate: this.mode === SEPARATE_ATTACHMENT_NAME_SINGULAR ? true : false
      };

      this.subscriptions.push(
        this.attachService.postCompanyAttachmentSetting(formData).pipe(
          concatMap(res => {
            const newSetting = { ...res, ...setting };
            delete newSetting.orderNo;
            return this.attachService.patchCompanySetting(res.id, newSetting);
          })
        ).subscribe(res => {
          this.notiService.showSuccess('Attachment Setting Created');
          this.attachService.addToCache(res);
          this.submitting = false;
          this.close();
        }, err => {
          this.notiService.notify(err);
          this.submitting = false;
          this.form.enable();
        })
      );
    }
  }

  modeChange(e) {

  }

  fileSelected(e) {
    if (e.value.length === 1) {
      this.type = e.value[0].name.substr(e.value[0].name.lastIndexOf('.') + 1);
      if (this.type === 'pdf' || this.type === 'doc' || this.type === 'docx'
        || this.type === 'xlsx' || this.type === 'xlsm' || this.type === 'xls') {
        this.fileChosen = true;
        this.wrongType = false;
      } else {
        this.wrongType = true;
      }
    }
  }

  setupForm() {
    this.form = this.formBuilder.group({
      description: [null, Validators.required],
      printOnlyIfFullyCompleted: false,
      printWithSalesQuote: true,
      printWithContractQuote: false,
      attachAtTheStart: false,
      useMailMerge: false,
      longDescription: null
    });
  }

  close() {
    this.activeModal.close();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
