import { EstimatingService } from './../../services/felixApi/estimating.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { DivisionService } from '../../services/felixApi/division.service';
import { NotificationService } from '../../services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DivisionLogoComponent } from './division-logo/division-logo.component';
import { AuthService } from '../../services/auth.service';
import { Recipe } from '../../dtos/recipe';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import { RecipeTypeEnum } from '../../dtos/recipe-type.enum';
import { TemplateTaskHeader } from '../../dtos/templateTaskHeader';
import { ColourJobComponent } from './colour-job/colour-job.component';
import { TrackingColourEnum } from '../../dtos/tracking-colour.enum';
import DataSource from 'devextreme/data/data_source';
import { DefaultRoleUserComponent } from './default-role-user/default-role-user.component';
import { District } from '../../dtos/district';

@Component({
  selector: 'js-divisions',
  templateUrl: './divisions.component.html',
  styleUrls: ['./divisions.component.scss']
})
export class DivisionsComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: DataSource;
  loading = false;
  gridHeight: number;
  isAdmin: boolean;
  recipes: Recipe[];
  dropDownOptions: { width: number; minHeight: number; };
  purchaseOrderSystemActive: boolean;
  trackingSystemActive: boolean;
  taskHeaders: TemplateTaskHeader[];
  isSuperUser: boolean;
  isConstructiveIntegrationEnabled: boolean;
  isLeadManagementSystemEnabled = false;
  trackingColourEnum = TrackingColourEnum;
  showCompanyOverrides = false;
  districts: District[];
  leadManagementSystemName: string;

  constructor(
    private divisionService: DivisionService,
    private modalService: NgbModal,
    private globalService: GlobalService,
    private notiService: NotificationService,
    private estimatingService: EstimatingService,
    private auth: AuthService,
    public gridService: GridService
  ) {
    this.reorder = this.reorder.bind(this);
    this.setDefaultUser = this.setDefaultUser.bind(this);
    this.dropDownOptions = { width: 800, minHeight: 500 };

    this.subscriptions.push(
      this.globalService.innerWidthChanged.subscribe(() => {
        this.gridHeight = window.innerHeight - 110;
      })
    );
  }

  ngOnInit(): void {
    this.gridHeight = window.innerHeight - 110;
    this.isAdmin = this.auth.isAdminOrSuper();
    this.isSuperUser = this.auth.isSuperUser();

    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.PurchaseOrderSystemActive) === 1) {
      this.purchaseOrderSystemActive = true;
    }
    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.TrackingSystemActive) === 1) {
      this.trackingSystemActive = true;
    }
    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.ConstructiveIntegrationEnabled)) {
      this.isConstructiveIntegrationEnabled = true;
    }

    const leadManagementSystemValue = this.globalService.getCompanyConfigValue(ConfigurationEnum.ActiveCampaignOrHubSpotIntegrationEnabled);
    if (leadManagementSystemValue) {
      this.isLeadManagementSystemEnabled = true;
      this.leadManagementSystemName = (leadManagementSystemValue < 3 ? 'Active Campaign' : 'HubSpot') + ' Integration Number';
    }

    this.setUpDataSource();
  }

  refresh() {
    this.grid.instance.refresh();
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.divisionService.getDivisionsAndRecipes().subscribe(res => {
            this.recipes = this.estimatingService.recipes.filter(i => i.recipeTypeId === RecipeTypeEnum.Recipe);
            this.taskHeaders = this.divisionService.taskHeaders?.filter(i => i.isActive && i.trackingWorkFlowTypeId === 1);
            this.districts = this.divisionService.districts;
            return resolve(res);
          }, err => {
            return reject(this.globalService.returnError(err));
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.divisionService.addDivision(values).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.globalService.returnError(err));
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.divisionService.updateDivision(encodeURIComponent(key), values).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.globalService.returnError(err));
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.divisionService.deleteDivision(encodeURIComponent(key)).subscribe(res => {
            return resolve();
          }, err => {
            return reject(this.globalService.returnError(err));
          }));
      }
    });
  }

  onInitNewRow(e) {
    e.data.isActive = true;
    e.data.leadManagementIntegrationNumber = 1; // default
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  reorder(e) {
    const visibleRows = e.component.getVisibleRows();
    const newOrderIndex = visibleRows[e.toIndex].data.orderNumber;
    this.grid.instance.beginCustomLoading('Re-ordering');

    this.subscriptions.push(
      this.divisionService.moveDivision(e.itemData.id, newOrderIndex).subscribe(() => {
        this.grid.instance.endCustomLoading();
        e.component.refresh();
      }, () => {
        this.notiService.showInfo('Cannot drop here');
        this.grid.instance.endCustomLoading();
      })
    );
  }

  manageFile(e) {
    if (e.row.isNewRow) {
      this.notiService.showInfo('Save record before adding file');
    } else {
      this.divisionService.currentDivision = e.data;
      const modalRef = this.modalService.open(DivisionLogoComponent);
      modalRef.componentInstance.attachmentParentRecordId = e.data.id;
      modalRef.componentInstance.claimMode = true;

      modalRef.result.then((refresh) => {
        this.divisionService.currentDivision = null;
        if (refresh) {
          this.refresh();
        }
      });
    }
  }

  onSelectionChanged(cellInfo, e, event) {
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0].id);
      e.component.close();
    }
  }

  onGridBoxOptionChanged(cellInfo, e) {
    if (e.name === 'value' && !e.value) {
      cellInfo.setValue(null);
    }
  }

  getGroupTitle(cellInfo) {
    return cellInfo.data.key.split(';')[1];
  }

  changeColour(e) {
    // set the tracking colour for the task grids
    const rowIndex = this.grid.instance.getRowIndexByKey(e.row.data.id);

    const modalRef = this.modalService.open(ColourJobComponent, { windowClass: 'modal-1000' });
    modalRef.result.then(trackingColour => {
      this.grid.instance.cellValue(rowIndex, 'trackingColour', trackingColour);
      this.grid.instance.saveEditData();
      this.grid.instance.refresh();
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Show Company Overrides',
          value: this.showCompanyOverrides,
          rtlEnabled: true,
          onValueChanged: this.showCompanyOverridesChanged.bind(this)
        }
      }
    );
  }

  showCompanyOverridesChanged() {
    this.showCompanyOverrides = !this.showCompanyOverrides;
  }

  setDefaultUser(e) {
    if (e.row.isNewRow) {
      this.notiService.showInfo('Save record before adding file');
    } else {
      this.divisionService.currentDivision = e.row.data;
      const modalRef = this.modalService.open(DefaultRoleUserComponent);

      modalRef.result.then((refresh) => {
      });
    }
  }

  onColourValueChanged(cellInfo, e) {
    cellInfo.setValue(e.value);
  }
}
