import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { UserTypeEnum } from '../dtos/user';
import { Company } from '../dtos/company';
import { User } from '../dtos/user';
import { AuthService } from '../services/auth.service';
import { GlobalService } from '../services/global.service';
import { UserService } from '../services/felixApi/user.service';
import { NotificationService } from '../services/notification.service';
import { CompanyService } from '../services/felixApi/company.service';
import { LogService } from '../services/log.service';
import { AuthApiService } from '../services/felixApi/auth-api.service';
import { ConfigurationEnum } from '../dtos/configuration-enum';
import { environment } from '../../environments/environment';

@Component({
  selector: 'js-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  COMPONENT_NAME = 'welcome';
  userTypeEnum = UserTypeEnum;
  userCompanies: Company[];
  currComp: Company;
  currUser: User;
  currUserEmail: string;
  multipleCompanies = false;
  admin = false;
  loading = true;
  innerWidth: number;
  compForm: UntypedFormGroup;
  currentVersion: string;

  @ViewChild('ParentDiv', { static: true }) parentDiv: ElementRef;
  purchaseOrderSystemActive: boolean;
  trackingSystemActive: boolean;
  @HostListener('window:resize') onResize() {
    if (this.parentDiv) { // guard against resize before view is rendered
      this.innerWidth = this.parentDiv.nativeElement.clientWidth;
    }
  }

  constructor(
    private globalService: GlobalService,
    private notiService: NotificationService,
    private userService: UserService,
    private router: Router,
    private logService: LogService,
    private auth: AuthService,
    private authApi: AuthApiService,
    private compService: CompanyService) { }

  ngOnInit() {
    if (this.parentDiv) { // guard against resize before view is rendered
      this.innerWidth = this.parentDiv.nativeElement.clientWidth;
    }
    this.currComp = this.globalService.getCurrentCompany();
    this.globalService.setAreaSelected('welcome');

    this.currentVersion = this.auth.currentVersion;

    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.PurchaseOrderSystemActive) === 1) {
      this.purchaseOrderSystemActive = true;
    }
    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.TrackingSystemActive) === 1) {
      this.trackingSystemActive = true;
    }

    this.currUserEmail = this.auth.getUserEmail();
    this.currUser = this.auth.getCurrentUser();
    if ((this.currComp && this.currComp.userTypeId === this.userTypeEnum.Admin) || (this.currUser && this.currUser.isSuperUser)) {
      this.admin = true;
    }
    this.getCompanies();
  }

  getCompanies() {
    this.userCompanies = this.compService.getCurrentCompanies();
    if (this.userCompanies) {
      this.populateCompanies();
    } else {
      if (this.auth.getUserEmail()) {
        this.authApi.getAuthorisedCompanies().subscribe(companies => {
          this.userCompanies = companies;
          this.populateCompanies();
        },
          err => {
            this.logService.log(this.COMPONENT_NAME, 'populateCompanies()', err,
              'Error getting companies', true, false);
            this.currUser = null;
            this.notiService.notify(err);
            this.loading = false;
          });
      } else {
        this.signOut();
      }
    }
  }

  populateCompanies() {
    this.compService.setCurrentCompanies(this.userCompanies);
    this.loading = false;
    if (this.userCompanies.length === 1) {
      this.multipleCompanies = false;
      this.currComp = this.userCompanies[0];
      if (!this.auth.signedIn) {
        this.compSelected();
      }
    } else {
      this.multipleCompanies = true;
    }
  }

  compSelected() {
    this.loading = true;
    this.globalService.menuDisable.emit(true);
    this.globalService.setCurrentCompany(this.currComp);

    // get the user master - only have all user info once a company is selected
    // and that is when we call auth service signIn()
    this.userService.getUser(this.currComp.userId).subscribe(
      currUser => {
        this.currUser = currUser;
        this.auth.setCurrentUser(this.currUser);
        this.auth.signIn();
        this.globalService.setCurrentCompany(this.currComp); // run again to get the perms
        if (this.auth.isAdminOrSuper() || this.currComp.userTypeId === this.userTypeEnum.Admin) {
          this.admin = true;
        } else {
          this.admin = false;
        }
        if (!this.admin) {
          this.authApi.getAreaPermissions().subscribe(permissions => {
            this.auth.setPermissions(permissions);
            const perm = this.auth.getSelectionsPermissions('OptionLists');
            if (perm === 'Write' || perm === 'Admin') {
              this.globalService.setAreaSelected('optionLists');
              this.router.navigateByUrl('/optionlists');
              this.globalService.menuDisable.emit(false);
            } else {
              this.notiService.showError('No permission to access this application');
              setTimeout(() => {
                this.signOut();
              }, 4000); // give time to respond
            }
          });
        } else {
          this.globalService.setAreaSelected('optionLists');
          this.router.navigateByUrl('/optionlists');
          this.globalService.menuDisable.emit(false);
        }
      },
      err => {
        this.logService.log(this.COMPONENT_NAME, 'compSelected()', err,
          'Error getting user', true, false);
        this.currUser = null;
        this.notiService.notify(err);
        this.loading = false;
        this.globalService.menuDisable.emit(false);
      });
  }

  compareComps(comp1: Company, comp2: Company) {
    if (!comp1 || !comp2) {
      return false;
    } else {
      return comp1.id === comp2.id;
    }
  }

  signOut() {
    this.loading = true;
    this.auth.signOut();
  }

  launchAdminPortal() {
    window.open(environment.adminAppUrl);
  }

  launchTruthEngine() {
    window.open(environment.addendaAppUrl);
  }

  launchEstConfig() {
    window.open(environment.estConfigAppUrl);
  }

  launchTracking() {
    window.open(environment.trackingAppUrl);
  }

  launchPurchaseOrders() {
    window.open(environment.ordersAppUrl);
  }

  clearCache() {
    localStorage.clear();
    this.notiService.showInfo('Cache cleared.');
    setTimeout(() => {
      this.signOut();
    }, 5000);
  }
}
