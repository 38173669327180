import { SharedModule } from './../shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HouseTypesComponent } from './house-types/house-types.component';
import { HouseTypeApiService } from './house-types/services/house-type.api.service';
import { HouseTypeModsComponent } from './house-types/house-type-mods/house-type-mods.component';
import { HouseModModalComponent } from './house-types/house-mod-modal/house-mod-modal.component';
import { HouseTypeService } from './house-types/services/house-type.service';
import { DxButtonModule, DxDataGridModule, DxSelectBoxModule } from 'devextreme-angular';
import { CompanyActivitiesComponent } from './company-activities/company-activities.component';
import { InformationTypesComponent } from './information-types/information-types.component';
import { MaintenanceComponent } from './maintenance.component';
import { AttachmentSettingsComponent } from './attachment-settings/attachment-settings.component';
import { AddAttachSettingComponent } from './attachment-settings/add-attach-setting/add-attach-setting.component';
import { ManageAttachFileComponent } from './attachment-settings/manage-attach-file/manage-attach-file.component';
import { ClaimsComponent } from './claims/claims.component';
import { ClaimsService } from './claims/services/claims.service';
import { ClaimApiService } from './claims/services/claim.api.service';
import { ClaimLinesComponent } from './claims/claim-lines/claim-lines.component';
import { ClientWelcomeComponent } from './client-welcome/client-welcome.component';
import { AttachmentGridComponent } from './attachment-settings/attachment-grid/attachment-grid.component';
import { DivisionsComponent } from './divisions/divisions.component';
import { DivisionLogoComponent } from './divisions/division-logo/division-logo.component';
import { HouseMastersComponent } from './house-masters/house-masters.component';
import { HouseSpecificationsComponent } from './house-specifications/house-specifications.component';
import { BanksComponent } from './banks/banks.component';
import { ColourJobComponent } from './divisions/colour-job/colour-job.component';
import { DefaultRoleUserComponent } from './divisions/default-role-user/default-role-user.component';

@NgModule({
    imports: [
        CommonModule,
        DxDataGridModule,
        DxSelectBoxModule,
        DxButtonModule,
        SharedModule
    ],
    declarations: [
        MaintenanceComponent,
        HouseTypesComponent,
        HouseTypeModsComponent,
        HouseModModalComponent,
        CompanyActivitiesComponent,
        InformationTypesComponent,
        AttachmentSettingsComponent,
        AddAttachSettingComponent,
        ManageAttachFileComponent,
        ClaimsComponent,
        ClaimLinesComponent,
        ClientWelcomeComponent,
        AttachmentGridComponent,
        DivisionsComponent,
        DivisionLogoComponent,
        HouseMastersComponent,
        HouseSpecificationsComponent,
        BanksComponent,
        ColourJobComponent,
        DefaultRoleUserComponent
    ],
    providers: [
        HouseTypeApiService,
        HouseTypeService,
        ClaimsService,
        ClaimApiService
    ],
    exports: []
})
export class MaintenanceModule { }
