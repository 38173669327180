import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { UserTypeEnum } from '../../dtos/user';
import { Company } from '../../dtos/company';
import { GlobalService } from '../../services/global.service';
import { AuthService } from '../../services/auth.service';
import { CompanyService } from '../../services/felixApi/company.service';
import { CompanyConfiguration } from '../../dtos/company-configuration';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import { NotificationService } from '../../services/notification.service';
import { AreaPermission } from '../../dtos/areaPermission';
import { AuthApiService } from '../../services/felixApi/auth-api.service';


@Component({
    selector: 'js-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

    isCollapsed = true;
    signedIn = false;
    superUser = false;
    admin = false;
    currComp: Company;
    areaSelected = '';
    disabledMenu = false;

    hiddenOptionLists = true;
    hiddenBoards = true;
    hiddenNotifications = true;
    constructionActivitiesOn = false;
    manualOrdersOn = false;

    companyConfiguration: CompanyConfiguration[];

    phoneSize: boolean;
    userTypeEnum = UserTypeEnum;

    subscriptions: Subscription[] = [];
    innerWidth: number;
    permissions: AreaPermission[];


    constructor(
        private globalService: GlobalService,
        private auth: AuthService,
        private authApi: AuthApiService,
        private cd: ChangeDetectorRef,
        private compService: CompanyService,
        private notiService: NotificationService
    ) {
        this.subscribeToLoginChanges();
        this.subscribeToAreaChanges();
        this.subscribeToCompanyChanges();
        this.subscribeToInnerWidth();
        this.subscribeToMenuDisable();
        this.subscribeToConfigChange();
    }

    ngOnInit() {
        this.innerWidth = window.innerWidth;
    }

    /* If a permissionType exists (null = no access) and a company has been chosen unlock app area */
    updatePermissions() {
        const companySet = this.globalService.isCompSet();
        const superPerms = (this.superUser || this.admin);
        if (superPerms && companySet) {
            this.superPermissions();
        } else {
            this.permissions.forEach(perm => {
                const allowed = perm.permissionType;

                switch (perm.applicationArea) {
                    case 'OptionLists': {
                        if (allowed && allowed === 'Admin' && companySet) {
                            this.hiddenOptionLists = false;
                            this.hiddenBoards = false;
                        }
                        break;
                    }
                }
            });
        }
    }

    menuPress(area) {
        this.globalService.setAreaSelected(area);
    }

    superPermissions() {
        this.hiddenOptionLists = false;
        this.hiddenNotifications = false;
        this.hiddenBoards = false;
    }

    subscribeToLoginChanges() {
        this.subscriptions.push(this.auth.isLoggedInChanged.subscribe(
            (isLoggedIn: boolean) => {
                if (!isLoggedIn) {
                    this.signedIn = false;
                } else {
                    this.getCompanyConfigurations();

                    this.signedIn = true;
                    this.superUser = this.auth.isSuperUser();
                    if (this.superUser) {
                        this.superPermissions();
                    }
                    this.cd.detectChanges();  // update view
                }
            }
        ));
    }

    subscribeToAreaChanges() {
        this.subscriptions.push(this.globalService.areaChanged.subscribe(area => {
            this.areaSelected = area;
        }
        ));
    }

    subscribeToCompanyChanges() {
        this.subscriptions.push(this.globalService.companySelected.subscribe(selectedComp => {
            this.currComp = selectedComp;
            if (this.auth.isAdminOrSuper()) {
                this.admin = true;
            } else {
                this.admin = false;
            }
            if (!this.superUser) {
                this.authApi.getAreaPermissions().subscribe(permissions => {
                    this.permissions = permissions;
                    this.updatePermissions();
                    this.auth.setPermissions(permissions);
                });
            }
        }
        ));
    }

    subscribeToInnerWidth() {
        this.subscriptions.push(this.globalService.innerWidthChanged.subscribe(
            width => {
                this.innerWidth = width;
            }
        ));
    }

    subscribeToMenuDisable() {
        this.subscriptions.push(this.globalService.menuDisable.subscribe(
            disable => {
                if (disable) {
                    this.disabledMenu = true;
                } else {
                    this.disabledMenu = false;
                }
            }
        ));
    }

    subscribeToConfigChange() {
        this.globalService.compConfigChanged.subscribe(x => {
            this.processCompanyConfigurations(this.globalService.companyConfiguration);
        });
    }

    getCompanyConfigurations() {
        this.compService.getCompanyConfigurations().subscribe(
            companyConfiguration => {
                this.processCompanyConfigurations(companyConfiguration);
            },
            err => {
                this.notiService.notify(err);
            });
    }

    processCompanyConfigurations(configs: CompanyConfiguration[]) {
        this.companyConfiguration = configs;
        this.globalService.companyConfiguration = this.companyConfiguration;

        // check for manual orders
        this.manualOrdersOn = false;
        this.constructionActivitiesOn = false;
        this.companyConfiguration.forEach(config => {
            if (config.configurationId === ConfigurationEnum.ManualOrders) {
                this.manualOrdersOn = true;
            }
            if (config.configurationId === ConfigurationEnum.ConstructionActivitiesUsed && config.configurationValue === 1) {
                this.constructionActivitiesOn = true;
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }
}
