import { Injectable, EventEmitter } from '@angular/core';

import { Company } from '../dtos/company';
import { TruthEngineSession, SessionName } from '../dtos/session';
import { CompanyConfiguration } from '../dtos/company-configuration';


@Injectable({
    providedIn: 'root',
})
export class GlobalService {

    private currentCompany: Company;

    menuDisable = new EventEmitter<boolean>();

    innerWidth: number;
    innerWidthChanged = new EventEmitter<number>();

    private areaSelected = '';
    areaChanged = new EventEmitter<string>();

    companySelected = new EventEmitter<Company>();
    companyConfiguration: CompanyConfiguration[];
    compConfigChanged = new EventEmitter<null>();

    refreshErrorEvent = new EventEmitter<boolean>();

    constructor() { }

    isHttps() {
        return window.location.protocol.startsWith('https') || window.location.hostname === 'localhost';
    }

    getCompanyConfigValue(id: number): number {
        return this.companyConfiguration?.find(i => i.configurationId === id)?.configurationValue;
    }

    setInnerWidth(width: number) {
        this.innerWidth = width;
        this.innerWidthChanged.emit(this.innerWidth);
    }

    setAreaSelected(area) {
        this.areaSelected = area;
        this.setSessionAtt('areaSelected', area);
        this.areaChanged.emit(this.areaSelected);
    }

    setCurrentCompany(company: Company) {
        this.currentCompany = company;
        this.setSessionAtt('currentCompanyId', company.id);
        this.companySelected.emit(this.currentCompany);
    }

    getCurrentCompany() {
        return this.currentCompany;
    }

    clearCurrentCompany() {
        this.currentCompany = null;
    }

    refreshError() {
        this.refreshErrorEvent.emit(true);
    }

    isCompSet() {
        if (this.currentCompany) {
            return true;
        } else {
            return false;
        }
    }

    getCurrentCompanyId(): string {
        if (this.currentCompany && this.currentCompany.id !== null) {
            return this.currentCompany.id.toString();
        }
        return '';
    }

    isSalesVariationsActive() {
        return this.currentCompany.salesVariationsActive;
    }

    getSessionObject(): TruthEngineSession {
        if (sessionStorage.getItem(SessionName)) {
            return JSON.parse(sessionStorage.getItem(SessionName));
        } else {
            const session = new TruthEngineSession();
            sessionStorage.setItem(SessionName, JSON.stringify(session));
            return JSON.parse(sessionStorage.getItem(SessionName));
        }
    }

    setSessionAtt(att: string, obj: any) {
        const session = this.getSessionObject();
        session[att] = obj;
        const sessionString = JSON.stringify(session);
        sessionStorage.setItem(SessionName, sessionString);
    }

    sortBy(name: string, secondarySort: boolean | ((o, p) => number), isAscending = true): (o, p) => number {
        const reverseMutliplier = isAscending ? 1 : -1;
        return function (o, p) {
            let a, b;
            let result;
            a = o[name];
            b = p[name];
            if (a === b) {
                return typeof secondarySort === 'function' ? secondarySort(o, p) : 0;
            }
            if (typeof a === typeof b) {
                result = a < b ? -1 : 1;
            } else {
                result = typeof a < typeof b ? -1 : 1;
            }
            return result * reverseMutliplier;
        };
    }

    returnError(err: any): string {
      console.log(JSON.stringify(err));
      if (err && err.error && err.error.message) {
        return JSON.stringify(err.error.message);
      } else if (err && err.error && err.error.detail) {
        return JSON.stringify(err.error.detail);
      } else if (err && err.error && err.error.Description && err.error.Description.length) {
        return JSON.stringify(err.error.Description[0]);
      } else {
        return JSON.stringify(err);
      }
    }
}
