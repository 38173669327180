
export default {

    DefaultVariationSubject: 'Variation status notification:  Job [JobNumber] Variation [VariationNumber]',
    DefaultVariationMessage: 'Please be advised that Variation [VariationNumber] ' +
                    'Titled [VariationTitle] for Job [JobNumber] at [SiteAddress] ' +
                    'has been marked [StatusDescription].',
    VariationEmailVariables: ['[VariationNumber]', '[VariationTitle]', '[JobNumber]', '[SiteAddress]', '[StatusDescription]'],

    DefaultClientSubject: 'Truth Engine notification:  Job [JobNumber] Updated!',
    DefaultClientMessage: 'Please be advised that documents have been updated for Job [JobNumber] at [SiteAddress].',
    ClientEmailVariables: ['[JobNumber]', '[SiteAddress]'],

    DefaultConfirmation: `Do you wish to revert Subject and Message texts to their defaults?
                    <br><br> This action will not be saved until you select Submit, however you will lose any entered text.`
};
