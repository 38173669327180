import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Company } from '../../dtos/company';
import { CompanyRole, CompanyRoleUser } from '../../dtos/roles';
import { CompanyConfiguration } from '../../dtos/company-configuration';
import { HttpService } from '../http.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    baseURL = environment.apiUrl + '/companies/';
    rolesBaseURL = environment.apiUrl + '/company-roles';

    authCompanies: Company[];

    constructor(private http: HttpClient, private httpService: HttpService) { }

    setCurrentCompanies(comps: Company[]) {
        this.authCompanies = comps;
    }

    getCurrentCompanies() {
        return this.authCompanies;
    }

    getCompanies() {
        return this.http.get<Company[]>(this.baseURL, this.httpService.getHttpOptions());
    }

    getCompaniesByUserEmail(email: string) {
        return this.http.get<Company[]>(environment.apiUrl + '/email-addresses/' + email + '/authorised-companies',
            this.httpService.getHttpOptions());
    }

    getCompany(id) {
        return this.http.get<Company>(this.baseURL + id, this.httpService.getHttpOptions());
    }

    updateComp(company: Company) {
        const url = this.baseURL + company.id;
        return this.http.patch(url, JSON.stringify(company), this.httpService.getHttpOptions());
    }

    addComp(company: Company) {
        return this.http.post(this.baseURL, JSON.stringify(company), this.httpService.getHttpOptions());
    }


    /***************************************************** ROLES ************************************************************/


    getCompanyRoles(): Observable<CompanyRole[]> {
        return this.http.get<CompanyRole[]>(this.rolesBaseURL, this.httpService.getHttpOptions());
    }

    patchCompanyRoles(roles: CompanyRole[]) {
        const observableBatch = [];
        roles.forEach( role => {
            const url = this.rolesBaseURL + role.id;
            const res = this.http.patch(url, JSON.stringify(role), this.httpService.getHttpOptions());
            observableBatch.push(res);
        });
        return observableBatch;
    }

    patchCompanyRole(role:  CompanyRole) {
        const url = this.rolesBaseURL + role.id;
        return this.http.patch(url, JSON.stringify(role), this.httpService.getHttpOptions());
    }

    getCompanyRoleUsers(roleId: number): Observable<CompanyRoleUser[]> {
        const url = this.rolesBaseURL + roleId + '/users';
        return this.http.get<CompanyRoleUser[]>(url, this.httpService.getHttpOptions());
    }

    addCompanyRoleUser(companyRoleId: number, userId: number) {
        const url = this.rolesBaseURL + companyRoleId + '/users';
        return this.http.post(url, JSON.stringify({userId: userId}), this.httpService.getHttpOptions());
    }

    deleteCompanyRoleUser(companyRoleId: number) {
        const url = environment.apiUrl + '/company-role-users/' + companyRoleId;
        return this.http.delete<CompanyRoleUser>(url, this.httpService.getHttpOptions());
    }


    /***************************************************** CONFIG ************************************************************/


    getCompanyConfigurations() {
        return this.http.get<CompanyConfiguration[]>(environment.apiUrl + '/company-configurations',
        this.httpService.getHttpOptions());
    }

}
