import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { HouseType, HouseTypeModification } from '../../dtos/house-type';
import { GlobalService } from '../../services/global.service';
import { HouseTypeService } from './services/house-type.service';
import { IJob } from '../../dtos/job';
import { JobService } from '../../services/job.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { GridService } from '../../services/grid.service';
import { MaintenanceHelperService } from '../maintenance-helper.service';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { Recipe } from '../../dtos/recipe';
import { NotificationService } from '../../services/notification.service';
import { HouseTypeApiService } from './services/house-type.api.service';
import { HouseOptionsGridComponent } from '../../option-lists/house-options-grid/house-options-grid.component';
import DataSource from 'devextreme/data/data_source';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'js-house-types',
  templateUrl: './house-types.component.html',
  styleUrls: ['./house-types.component.scss']
})
export class HouseTypesComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  addModSub = new Subject<void>();
  addTypeSub = new Subject<void>();

  jobs: IJob[];

  subscriptions: Subscription[] = [];
  dataSource: any = {};

  modMode = false;
  titleText: string;
  innerWidth: number;

  statusFilterMode = 'all';
  query = '';
  cssSmlWidth = 500;
  statuses: string[];
  currentFilter = 'Active Only';
  loading = true;
  recipes: Recipe[];
  dropDownOptions: object;
  recipeData: DataSource;
  updatePricesPopup: boolean;
  reCostingDate: any;
  gridHeight: number;
  // houseMasters: HouseMaster[];
  // houseSpecifications: HouseSpecification[];

  constructor(
    private globalService: GlobalService,
    private houseTypeService: HouseTypeService,
    public houseTypeApiService: HouseTypeApiService,
    private jobService: JobService,
    public gridService: GridService,
    private estimatingService: EstimatingService,
    private helper: MaintenanceHelperService,
    private notiService: NotificationService,
    private modalService: NgbModal) {
    this.setupTypes();
    this.setHouseMasterCellValue = this.setHouseMasterCellValue.bind(this);
    this.setHouseSpecificationCellValue = this.setHouseSpecificationCellValue.bind(this);
    this.calculateHouseArea = this.calculateHouseArea.bind(this);
    this.calculateBlockFrontage = this.calculateBlockFrontage.bind(this);

    this.subscriptions.push(
      this.globalService.innerWidthChanged.subscribe(width => {
        this.innerWidth = width;
        this.gridHeight = window.innerHeight - 110;
      }),
      this.jobService.getJobsForCompany().subscribe(jobs => {
        this.jobs = jobs;
      }),
      this.houseTypeApiService.getHouseMasters(true).subscribe(res => {
        // this.houseMasters = res;
      }),
      this.houseTypeApiService.getHouseSpecifications(true).subscribe(res => {
        // this.houseSpecifications = res;
      })
    );

    this.dataSource = this.houseTypeService.getHouseTypeDataSource();
    this.dropDownOptions = { width: 800, minHeight: 500 };
  }

  ngOnInit() {
    this.innerWidth = this.globalService.innerWidth;
    this.gridHeight = window.innerHeight - 110;
    this.getRecipes();
  }

  getRecipes() {
    // get the recipes for the list
    this.subscriptions.push(
      this.estimatingService.getRecipeWithSellingRates(true)
        .subscribe(
          recipes => {
            this.recipes = recipes;

            this.recipeData = new DataSource({
              key: 'id',
              loadMode: 'raw',
              load: () => this.recipes
            });

            this.loading = false;
          },
          err => {
            this.notiService.notify(err);
            this.loading = false;
          })
    );
  }

  addType() {
    if (this.modMode) {
      this.addModSub.next();
    } else {
      this.addTypeSub.next();
    }
  }

  /* refresh house types + jobs */
  refresh() {
    this.houseTypeService.clearCaches();
    this.grid.instance.refresh();
    this.jobService.getJobsForCompany().subscribe(jobs => {
      this.jobs = jobs;
    });
  }

  filterTypes = (type: HouseType | HouseTypeModification) => {
    if (this.statusFilterMode === 'all') {
      if (this.query === '') {
        return true;
      }
      return (type.description.toLowerCase().indexOf(this.query) > -1);
    } else {
      let activeBool: Boolean;
      this.statusFilterMode === 'active' ? activeBool = true : activeBool = false;
      if (this.query === '' && type.isActive === activeBool) {
        return true;
      }
      return (type.description.toLowerCase().indexOf(this.query) > -1) && type.isActive === activeBool;
    }
  }

  toggleMode() {
    if (this.modMode) {
      this.setupTypes();
      this.helper.houseTypeModMode.next(false);
    } else {
      this.setupMods();
      this.helper.houseTypeModMode.next(true);
    }
  }

  setupTypes() {
    this.titleText = '';
    this.modMode = false;
    this.dataSource = this.houseTypeService.getHouseTypeDataSource();
    this.statuses = ['Active Only', 'All', 'Not Active'];
  }

  setupMods() {
    this.titleText = 'House Type Modifications';
    this.modMode = true;
    this.dataSource = this.houseTypeService.getHouseModDataSource();
  }

  selectStatus(data) {
    this.currentFilter = data.value;
    this.runFilter();
  }

  onContentReady(event) {
    // set filter
    this.runFilter();
  }

  customiseToolbar(e, modMode = false) {
    this.gridService.addRefreshBtnToToolbar(e);
    this.gridService.addItemTotoolbar(e, 'modeTemplate', false);
    if (!modMode) {
      this.gridService.addItemTotoolbarPosition(e, 'activeFilterTemplate', true, 5, 3);

      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            type: 'default',
            stylingMode: 'outlined',
            text: 'House Options',
            onClick: this.openHouseOptions.bind(this)
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            type: 'default',
            stylingMode: 'outlined',
            text: 'Update Cost Price',
            onClick: this.updateSellingPricesCheck.bind(this)
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            type: 'default',
            stylingMode: 'outlined',
            text: 'Reset Layout',
            onClick: this.clearStatePersistance.bind(this)
          }
        });
    }
  }

  runFilter() {
    if (this.currentFilter === 'All') {
      this.grid.instance.clearFilter();
    } else if (this.currentFilter === 'Active Only') {
      this.grid.instance.filter(['isActive', '=', true]);
    } else {
      this.grid.instance.filter(['isActive', '=', false]);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });

    this.houseTypeService.clearCaches();
  }

  onSelectionChanged(cellInfo, e, event) {
    // console.log(event);
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0]);
      e.component.close();
    }
  }

  setRecipeCellValue(rowData, value) {
    // console.log('setValue ' + JSON.stringify(value));
    if (value) {
      rowData.recipeId = value.id;
    } else {
      rowData.recipeId = null;
    }
  }

  onRecipeChanged(cellInfo, e) {
    // console.log(e);
    if (!e.value) {
      cellInfo.setValue({ id: null });
    }
  }

  updateSellingPricesCheck() {
    this.updatePricesPopup = true;
  }

  updateSellingPrices() {
    // update the selling prices from recipes
    this.loading = true;
    this.updatePricesPopup = false;
    this.subscriptions.push(
      this.houseTypeService.updateSellingPrices(this.reCostingDate)
        .subscribe(
          () => {
            this.houseTypeService.clearCaches();
            this.dataSource = this.houseTypeService.getHouseTypeDataSource();
            this.loading = false;
          },
          err => {
            this.notiService.notify(err);
            this.loading = false;
          })
    );
  }

  clearStatePersistance() {
    this.loading = true;
    localStorage.removeItem('house-types');
    setTimeout(() => {
      this.loading = false;
    }, 300); // wait
  }

  calculateMarkup(data) {
    if (!data.salesPrice || !data.costToCompany) {
      return null;
    }
    return ((data.salesPrice * 10 / 11) - data.costToCompany) / data.costToCompany * 100;
  }

  calculateSalesPrice(data) {
    if (!data.costToCompany || !data.markupPercent) {
      return null;
    }
    return data.costToCompany * (1 + data.markupPercent / 100) * 1.1;
  }

  calculateMarkupAmount(data) {
    if (!data.salesPrice || !data.costToCompany) {
      return null;
    }
    return (data.salesPrice * 10 / 11) - data.costToCompany;
  }

  calculateMargin(data) {
    if (!data.salesPrice || !data.costToCompany) {
      return null;
    }
    return ((data.salesPrice * 10 / 11) - data.costToCompany) / data.salesPrice * 100;
  }

  calculateHouseArea(data) {
    const houseMaster = this.houseTypeApiService.houseMasters.find(i => i.id === data.houseMasterId);
    return houseMaster?.houseArea;
  }

  calculateBlockFrontage(data) {
    const houseMaster = this.houseTypeApiService.houseMasters.find(i => i.id === data.houseMasterId);
    return houseMaster?.blockFrontage;
  }

  onCellPrepared(e) {
    if (e.rowType === 'data' && e.column.dataField === 'salesPrice') {
      // e.cellElement.style.backgroundColor = '#f5f5f5';
      e.cellElement.style.color = 'green';
      e.cellElement.style.fontWeight = 'bold';
      if (e.data.costToCompany && e.data.markupPercent
        && e.data.salesPrice < (e.data.costToCompany * (1 + e.data.markupPercent / 100) * 1.1)) {
        e.cellElement.style.color = 'red';
      }
    }
  }

  setHouseMasterCellValue(rowData, value, originalData) {
    if (value) {
      rowData.houseMasterId = value;
      this.setRowDescription(value, originalData.houseSpecificationId, rowData);
    } else {
      rowData.houseMasterId = null;
    }
  }

  setHouseSpecificationCellValue(rowData, value, originalData) {
    if (value) {
      rowData.houseSpecificationId = value;
      this.setRowDescription(originalData.houseMasterId, value, rowData);
    } else {
      rowData.houseSpecificationId = null;
    }
  }

  setRowDescription(houseMasterId: number, houseSpecificationId: number, rowData: any) {
    rowData.description = '';

    const houseMaster = this.houseTypeApiService.houseMasters.find(i => i.id === houseMasterId);
    if (houseMaster) {
      rowData.description = houseMaster.description;
    }

    const houseSpecification = this.houseTypeApiService.houseSpecifications.find(i => i.id === houseSpecificationId);
    if (houseSpecification) {
      rowData.description += ' - ' + houseSpecification.description;
    }
  }

  onInitNewRow(e) {
    e.data.isActive = true;
  }

  openHouseOptions() {
    const modalRef = this.modalService.open(HouseOptionsGridComponent, { windowClass: 'modal-houseOptions' });
    modalRef.result.then(() => { });
  }
}
